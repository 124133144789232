<template>
  <div class="page-table sozlesmeTanim mainDiv mb-30" id="affix-container">
    <div class="animated fadeInUp card-base baslikBg">Sözleşme Tanımla</div>

    <el-row
      :gutter="10"
      v-loading="loading"
      :element-loading-text="$t('src.views.apps.sozlesme.tanimlama.addLoading')"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <el-form
        status-icon
        :model="sozlesmeForm"
        :rules="rulesSozlesme"
        ref="sozlesmeForm"
        class="demo-ruleForm"
      >
        <el-col :lg="18" :md="18" :sm="24" :xs="24">
          <div
            class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft pb-10 pt-10"
          >
            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item
                  label="Sözleşme Kategori"
                  prop="sozlesmeKategoriID"
                  >:
                  <el-select
                    style="width: 100%"
                    size="small"
                    v-model="sozlesmeForm.sozlesmeKategoriID"
                    placeholder="Sözleşme Kategori Seçiniz"
                    filterable
                    :filter-methos="filterMethod"
                  >
                    <el-option
                      aria-autocomplete="none"
                      v-for="item in sozlesmeForm.kategoriler"
                      :key="item.sozlesmeKategoriID"
                      :label="item.sozlesmeKategoriAdi"
                      :value="item.sozlesmeKategoriID"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Sözleşme Kapsamı" prop="sozlesmeBaslik"
                  >:
                  <el-input
                    auto-complete="off"
                    size="small"
                    v-model="sozlesmeForm.sozlesmeBaslik"
                    :placeholder="'Sözleşme Kapsamı Giriniz'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row>
              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Yüzde Değer" prop="yuzdeDeger"
                  >:
                  <el-input
                    size="small"
                    auto-complete="off"
                    v-model="sozlesmeForm.yuzdeDeger"
                    @input="formatYuzdeDeger"
                    :placeholder="'Yüzde Değer Giriniz'"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item
                  label="Tahmini Başlama Süresi"
                  prop="baslangicSuresi"
                  >:
                  <el-input
                    size="small"
                    auto-complete="off"
                    v-model="sozlesmeForm.baslangicSuresi"
                    v-mask="'##########'"
                    :placeholder="'Kaç Gün Sonra Başlayacağınızı Belirtiniz'"
                  ></el-input>
                </el-form-item>
              </el-col>

              <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                <el-form-item label="Tahmini İş Süresi" prop="isSuresi"
                  >:
                  <el-input
                    size="small"
                    auto-complete="off"
                    v-model="sozlesmeForm.isSuresi"
                    v-mask="'##########'"
                    :placeholder="'Kaç Günde Biteceğini Belirtiniz'"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <!--
                                        <el-row>
                                            <el-col :lg="8" :md="8" :sm="24" :xs="24" class="col-p">
                                                    <el-form-item label="Sözleşmeye atamak istediğiniz rengi seçiniz:" prop="renkKodu"><br>
                                                        <el-color-picker size="medium" v-model="sozlesmeForm.renkKodu" ></el-color-picker>
                                                     </el-form-item>
                                            </el-col>

                                            <el-col :lg="16" :md="16" :sm="24" :xs="24" class="col-p">
                                                <el-form-item label="Renk Kodu" prop="renkKodu">:
                                                    <el-input size="small"
                                                        type="text"
                                                        :placeholder="'Renk Kodunu Hexadecimal Olarak Giriniz'"
                                                        v-model="sozlesmeForm.renkKodu">
                                                        </el-input>
                                                </el-form-item>
                                            </el-col>
                                        </el-row>
                                        -->
          </div>
        </el-col>

        <el-col :lg="6" :md="6" :sm="24" :xs="24">
          <el-collapse
            class="yanForm animated fadeInUp"
            v-model="activeCollapse"
          >
            <el-collapse-item
              class="card-shadow--medium"
              name="8"
              :title="$t('src.views.apps.genel.durum')"
            >
              <el-row>
                <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                  <el-form-item prop="durum">
                    <el-select
                      size="small"
                      :disabled="disabled"
                      v-model="sozlesmeForm.durum"
                      :placeholder="
                        $t('src.views.apps.messages.addBultenTitle')
                      "
                      style="width: 100%"
                    >
                      <el-option
                        value="0"
                        :label="$t('src.views.apps.durum.sozlesme.onay')"
                      ></el-option>
                      <el-option
                        value="1"
                        :label="$t('src.views.apps.durum.sozlesme.aktif')"
                      ></el-option>
                      <el-option
                        value="2"
                        :label="$t('src.views.apps.durum.sozlesme.askiyaAl')"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-button
                  size="mini"
                  v-on:click="sozlesmeEkle('sozlesmeForm', sozlesmeForm.durum)"
                  class="onayBtn"
                  type="primary"
                >
                  <label>Kaydet</label>
                </el-button>
                &nbsp;
                <el-popover placement="bottom" width="300" v-model="visibleSil">
                  <p>{{ $t("src.views.apps.messages.clearFormAlert") }}</p>
                  <div style="text-align: right; margin: 0">
                    <el-button
                      size="mini"
                      type="primary"
                      @click="resetForm('sozlesmeForm')"
                    >
                      {{ $t("src.views.apps.genel.yes") }}
                    </el-button>
                    <el-button size="mini" @click="visibleSil = false">
                      {{ $t("src.views.apps.genel.no") }}
                    </el-button>
                  </div>
                  <el-button size="mini" slot="reference">
                    {{ $t("src.views.apps.genel.temizle") }}
                  </el-button>
                </el-popover>
              </el-row>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import ClassicEditor from "../../../../node_modules/ckeditor5-build-classic";
import draggable from "vuedraggable";

import sozlesmeService from "../../../WSProvider/SozlesmeService";

import notification from "../../../notification";
import functions from "../../../functions";
import EventBus from "@/components/event-bus";
import Resim from "@/components/Resim.vue";
import Seo from "@/components/Seo.vue";
import Konumlar from "@/components/Konumlar.vue";

var moment = require("moment");
let time = moment().format("YYYY-MM-DD HH:mm:ss");

export default {
  name: "SozlesmeTanimla",
  components: {
    draggable,
    Resim,
    Seo,
    Konumlar,
  },
  watch: {
    imageList: function (val) {
      if (val.length === 1) {
        this.sozlesmeForm.resimKonum = "1";
        this.sozlesmeForm.galeriKonum = "0";
      } else if (val.length > 1) {
        this.sozlesmeForm.galeriKonum = "1";
      } else {
        this.sozlesmeForm.resimKonum = "0";
        this.sozlesmeForm.galeriKonum = "0";
      }
    },
  },
  computed: {
    imageList() {
      return this.sozlesmeForm.resim;
    },
  },
  mounted() {
    this.sozlesmeKategoriListesi();

    const self = this;
    EventBus.$on("sozlesmeTanimlaImage", function (bool, imageArray) {
      if (bool) {
        self.sozlesmeForm.resim = imageArray;
      }
    });

    EventBus.$on("sozlesmeTanimlaBelge", function (bool, belgeArray) {
      if (bool) {
        self.sozlesmeForm.belge = belgeArray;
      }
    });
  },
  data() {
    return {
      totalKategori: 0, // kategorilerin sayısını ilk önce cekerek kategori listesini komple cekmek için
      catLoading: false,

      loading: false,
      selectedImagesFromServer: [],
      path: sozlesmeService.path,
      imagePath: sozlesmeService.imagePath,
      belgePath: sozlesmeService.belgePath,

      videoVis: false,
      editor: ClassicEditor,
      editorConfig: {
        language: "tr",
      },
      activeCollapse: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11"],
      visibleSil: false,
      disabled: false,
      checkedTarih: false,
      dateRangeOptions: {
        firstDayOfWeek: 1,
      },
      sozlesmeForm: {
        sozlesmeBaslik: "",
        sozlesmeKategori: "",
        baslangicSuresi: "",
        isSuresi: "",
        kategoriler: [],
        adSoyad: "",
        firmaAdi: "",
        projeAdi: "",
        unvan: "",
        telefon: "",
        eposta: "",
        adres: "",
        website: "",
        video: "",
        icerik: "",
        resim: [],
        belge: [],
        link: "",
        resimKonum: "0",
        menuKonum: "0",
        galeriKonum: "0",
        tarih: time,
        bitisTarihi: "",
        durum: "1",
        seoBaslik: "",
        seoIcerik: "",
        yuzdeDeger: "",
        //renkKodu: '#000000'
      },
      yuzdeDegerOld: "",
      rulesSozlesme: {
        sozlesmeKategoriID: [
          {
            required: true,
            message: "Lütfen Sözleşme Kategori Seçiniz",
            trigger: "blur",
          },
        ],
        sozlesmeBaslik: [
          {
            required: true,
            message: "Lütfen Sözleşme Giriniz",
            trigger: "blur",
          },
        ],
        yuzdeDeger: [
          {
            required: true,
            message: "Lütfen Yüzde Değer Giriniz",
            trigger: "blur",
          },
        ],
        baslangicSuresi: [
          {
            required: true,
            message: "Lütfen Başlama Değeri Giriniz",
            trigger: "blur",
          },
        ],
        isSuresi: [
          {
            required: true,
            message: "Lütfen İş Süresi Giriniz",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    formatYuzdeDeger() {
      if (
        this.yuzdeDegerOld === "10" &&
        this.sozlesmeForm.yuzdeDeger === "100"
      ) {
        this.yuzdeDegerOld = "100";
      } else {
        if (this.yuzdeDegerOld === "100") {
          this.sozlesmeForm.yuzdeDeger = this.sozlesmeForm.yuzdeDeger.substring(
            0,
            3
          );
        } else {
          if (this.sozlesmeForm.yuzdeDeger.length <= 5) {
            console.log(this.sozlesmeForm.yuzdeDeger.length);
            this.sozlesmeForm.yuzdeDeger = this.sozlesmeForm.yuzdeDeger
              .replace(/\D/g, "")
              .replace(/\B(?=(\d{2})+(?!\d))/g, ".");
          } else {
            this.sozlesmeForm.yuzdeDeger =
              this.sozlesmeForm.yuzdeDeger.substring(0, 5);
          }
        }
      }
    },

    seoIcerikTespit() {
      this.sozlesmeForm.seoIcerik = this.sozlesmeForm.baslik.substring(0, 60);
    },
    handleSeo(seoBaslik, seoIcerik) {
      this.sozlesmeForm.seoBaslik = seoBaslik;
      this.sozlesmeForm.seoIcerik = seoIcerik;
    },

    handleKonum(resimKonum, menuKonum, galeriKonum) {
      // normal sartlarda ana sitede sayfanın düzeni kontrol edilebilir oluyormus. Yanı eklenen resimlerin konumu yukarıda mı asagıda mı olsun gibi. Ama suan da ona uygun bir yapı olmadıgı için component yukarıda yorum satırı olarak kaldı. Lazım oldugunda yorum satırı kaldırılıp kullanılır.
      this.sozlesmeForm.resimKonum = resimKonum;
      this.sozlesmeForm.menuKonum = menuKonum;
      this.sozlesmeForm.galeriKonum = galeriKonum;
    },

    filterMethod(query, item) {
      return item.label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
    },

    sozlesmeKategoriListesi() {
      try {
        this.loading = true;
        sozlesmeService
          .sozlesmeKategoriListesi()
          .then((response) => {
            localStorage.setItem("userDataBGSurec", response.token);
            if (response.status == 200) {
              this.sozlesmeForm.kategoriler = response.data;
            }
            this.loading = false;
          })
          .catch((err) => {
            if (err.responseJSON) {
              let error = err.responseJSON;
              if (error.status == 401) {
                notification.Status(150, this);
              } else if (error.status == 404) {
                this.sozlesmeForm.kategoriler = [];
              } else notification.Status("errorMsg", this, error.errMsg);
            } else {
              //notification.Status(503, this)
            }
            this.loading = false;
          });
      } catch (e) {
        // notification.Status("success", this, "An error occurred while fetching the customer list.")
        this.loading = false;
      }
    },

    sozlesmeEkle(formName) {
      this.$refs[formName].validate((valid, errors) => {
        if (valid) {
          this.$confirm(
            "Sözleşmeyi tanımlamak istediğinize emin misiniz?",
            this.$t("src.views.apps.genel.uyari"),
            {
              confirmButtonText: this.$t("src.views.apps.genel.yes"),
              cancelButtonText: this.$t("src.views.apps.genel.no"),
              type: "warning",
            }
          )
            .then(() => {
              this.loading = true;
              console.log(this.sozlesmeForm.durum);
              sozlesmeService
                .sozlesmeEkle(
                  this.sozlesmeForm.sozlesmeKategoriID,
                  this.sozlesmeForm.sozlesmeBaslik,
                  this.sozlesmeForm.yuzdeDeger,
                  this.sozlesmeForm.baslangicSuresi,
                  this.sozlesmeForm.isSuresi,
                  this.sozlesmeForm.durum
                )
                .then((response) => {
                  if (response.status == 200) {
                    localStorage.setItem("userDataBGSurec", response.token);
                    notification.Status("success", this, response.msg);
                    EventBus.$emit("sozlesmelistesi", true);
                    functions.sayfaKapat("sozlesmetanimla", this);
                    this.resetForm(formName);
                  }
                  this.loading = false;
                })
                .catch((err) => {
                  if (err.responseJSON) {
                    let error = err.responseJSON;
                    if (error.status == 401) {
                      notification.Status(150, this);
                    } else notification.Status("errorMsg", this, error.errMsg);
                  } else {
                    //notification.Status(503, this)
                  }
                  this.loading = false;
                });
            })
            .catch((e) => {});
        } else {
          for (var k in errors) {
            errors[k].forEach((element) => {
              notification.Status("warning", this, element.message);
            });
            this.loading = false;
          }
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.visibleSil = false;
    },
  },
};
</script>
